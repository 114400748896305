import React, { useEffect, useState } from 'react';
import styles from './IstAssessmentEnd.module.scss';

// 3RD PARTY
import { useHistory } from 'react-router';
import classNames from 'classnames';

// STORE
import { useDispatch, useSelector } from 'react-redux';
import { getAssessment, getAssessmentResult, getExtraCards } from 'store/actions';
import { selectAssessments } from 'store/selectors/assessment';

// OTHER COMPONENTS
import { Button, IstIndicator } from 'ui/basic';
import { BookModal } from 'ui/molecules/IstResult/BookModal';

// UTILS
import { useTranslate } from 'utils/translator';
import { getTopParentAssessmentId } from 'utils/assessment';
import {
  ASSESSMENT_RECURRENCE_TYPES,
  ASSESSMENT_TYPES,
} from 'utils/configuration/const/assessment-types';

// CONFIG & DATA

const IstAssessmentEnd = (props) => {
  const { assessmentDetails, extras = {} } = props;

  const {
    finishable,
    loadingDuring,
    setLoadingDuring,
    handleFinish,
    errorEnd,
  } = extras;

  const dispatch = useDispatch();
  const translate = useTranslate();
  const history = useHistory();

  const assessmentType = ASSESSMENT_TYPES.IST;
  const assessment = useSelector((state) => state.assessments.table[assessmentType]);
  const assessmentsFromStore = useSelector(selectAssessments);
  const topParent = getTopParentAssessmentId(assessmentType, assessmentsFromStore);
  const resultsRequestProcessed = assessment?.resultsRequestProcessed;
  const results = assessment?.results;

  localStorage.removeItem(`chat_${localStorage.getItem('token')}`);

  useEffect(() => {
    dispatch(getAssessmentResult(assessmentType));

    if (assessment?.recurrenceType === ASSESSMENT_RECURRENCE_TYPES.MANUAL) {
      dispatch(getAssessment(assessmentType));
    }
  }, [ dispatch, assessmentType, assessment?.recurrenceType ]);

  const cards = assessment?.extraCards;
  useEffect(() => {
    dispatch(getExtraCards(assessmentType));
  }, [ dispatch, assessmentType ]);

  const [ showVulnerablePage, setShowVulnerablePage ] = useState(false);
  useEffect(() => {
    if (assessment?.recurrenceType === ASSESSMENT_RECURRENCE_TYPES.MANUAL
      && assessmentDetails?.lastIterationDate && assessment?.date
    ) {
      const [ lastIterationDateString ] = assessmentDetails.lastIterationDate.toString().split('.');
      const [ currentAssessmentDateString ] = assessment.date.toString().split('.');
      if (lastIterationDateString === currentAssessmentDateString) {
        return;
      }
    }

    if (resultsRequestProcessed) {
      const total = results.find((r) => r.id === 'ist_total') || {};
      if (total.classification === 'vulnerable') {
        setShowVulnerablePage(true);
      } else {
        setShowVulnerablePage(false);
      }

      setLoadingDuring?.(false);
    }
  }, [
    assessment,
    assessmentDetails,
    resultsRequestProcessed,
    results,
    setLoadingDuring,
  ]);

  const [ showBookCoachingPage, setShowBookCoachingPage ] = useState(false);

  const handleViewResults = () => {
    handleFinish?.(() => history.push(`/my-profile/well-being?route=${topParent}`));
  };

  return (
    <div
      className={styles.assessmentEnd}
      data-test='AssessmentEnd'
    >
      { errorEnd && (errorEnd.message || translate('assessment_save_answers_error')) }

      { (!errorEnd && finishable) && (
        <div className={styles.gridContent}>
          <span className={styles.graphic} role='img' aria-label='applause'>&#128588;</span>

          <div className={classNames('bluTypeS', 'marginTopXs')}>
            { translate('assessment_success') }
          </div>

          { /* loadingDuring is true until the IST results are fetched */ }
          { (showVulnerablePage && !loadingDuring) && (
            <>
              <div className={styles.indicator}>
                <IstIndicator color='red' />
              </div>

              <div className={styles.textContent}>
                <div className={classNames('bluTypeLabelL', 'marginTopXs')}>
                  { translate('ist_assmnt_vulnerable_result_title') }
                </div>

                <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
                  { translate('ist_assmnt_vulnerable_result_copy') }
                </div>
              </div>

              <div className={styles.buttons}>
                { cards?.find((card) => card.type === 'coaching-booking') && (
                  <Button
                    size='M'
                    disabled={!finishable}
                    onClick={() => setShowBookCoachingPage(true)}
                  >
                    { translate('my_coaching_subtitle') }
                  </Button>
                ) }

                <Button
                  size='M'
                  looks='secondary'
                  disabled={!finishable}
                  onClick={handleViewResults}
                >
                  { translate('assessment_view_results') }
                </Button>
              </div>
            </>
          ) }

          { /* loadingDuring is true until the IST results are fetched */ }
          { (!showVulnerablePage && !loadingDuring) && (
            <>
              <div className={classNames('bluTypeCopy', 'marginTopS')}>
                { translate('disc_ass_success_copy') }
              </div>
              <Button
                size='M'
                looks='secondary'
                disabled={!finishable}
                onClick={handleViewResults}
              >
                { translate('assessment_view_results') }
              </Button>
            </>
          ) }
        </div>
      ) }

      { showBookCoachingPage && (
        <BookModal onClose={() => setShowBookCoachingPage(false)} />
      ) }
    </div>
  );
};

export default IstAssessmentEnd;
