// REACT, STYLE, STORIES & COMPONENT
import React, { useEffect, useState } from 'react';
import styles from './VacancyExternalRoleDetails.module.scss';

// ASSETS

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
import { ProfileSummary } from 'features/+candidates/pages/VacancySummary/ProfileSummary';
import { CollapsibleNext, TextEditor } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';
import { getAssessmentRange } from 'utils/roles';
import { sortAlphabetically } from 'utils/strings';
import { REFERENCE_PROFILE_TYPES } from 'utils/configuration/const/reference-profile';
import {
  ASSESSMENT_COMPLETED_STATUSES,
  ASSESSMENT_TYPES,
  NINE_LEVELS_ORDER,
} from 'utils/configuration/const/assessment-types';

// STORE
import * as api from 'api';

import {
  Big5Result,
  CompetenciesResult,
  NineLevelsResult,
  PotentialResult,
  RmpResult, SkillsResult,
  WorkPreferencesResult,
} from 'ui/molecules';

// CONFIG & DATA
// const Config = {};

// COMPONENT: VacancyExternalSummary
const VacancyExternalRoleDetails = (props) => {
  // PROPS
  const { vacancy, token } = props;

  // SPECIAL HOOKS
  const translate = useTranslate();

  // VACANCY ROLE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ role, setRole ] = useState();
  useEffect(() => {
    if (!vacancy.role) {
      return;
    }

    api.get(
      `rolemapping/roles/${vacancy.role.id}`,
      { expand: 'referenceProfile' },
      { Authorization: `Bearer ${token}` },
    ).then(({ ok, status, data }) => {
      const fetchedRole = { ...data };

      if (ok && status === 200) {
        // for manual roles profile values will be stored in 'generated property'
        // (for consistency with auto roles)
        if (fetchedRole.referenceProfile.type === REFERENCE_PROFILE_TYPES.MANUAL) {
          const profile = [ ...fetchedRole.referenceProfile.profile ];
          fetchedRole.referenceProfile.profile = { generated: profile };
        }

        setRole(fetchedRole);
      }
    });
  }, [ token, vacancy ]);

  // ASSESSMENTS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ assessments, setAssessments ] = useState([]);
  useEffect(() => {
    if (assessments && assessments.length > 0) {
      return;
    }

    api.get('/core/assessments').then(({ ok, status, data }) => {
      if (ok && status === 200) {
        const unorderedCoreAssessments = [];
        const orderedCoreAssessments = [];
        const customAssessments = [];

        // separate core and custom assessments into 2 different arrays
        data.assessments.forEach((assessment) => {
          if (Object.values(ASSESSMENT_TYPES).includes(assessment.id)) {
            unorderedCoreAssessments.push(assessment);
          } else {
            customAssessments.push(assessment);
          }
        });

        // order core assessments
        Object.values(ASSESSMENT_TYPES).forEach((assessmentId) => {
          const thisAssessment = unorderedCoreAssessments.find((a) => a.id === assessmentId);
          if (thisAssessment) {
            orderedCoreAssessments.push(thisAssessment);
          }
        });

        setAssessments([ ...orderedCoreAssessments, ...customAssessments ]);
      }
    });
  }, [ assessments ]);

  const renderReferenceProfile = () => {
    const referenceProfile = [];

    const targetProfile = role?.referenceProfile?.profile?.current?.length
      ? role.referenceProfile.profile.current
      : role?.referenceProfile?.profile?.generated;
    assessments.forEach((assessment) => {
      const assessmentProfile = targetProfile?.find(({ assessmentId }) => assessmentId === assessment.id);

      if (!assessmentProfile?.results) {
        return;
      }

      const results = [];
      assessmentProfile.results.forEach((r) => {
        let { dimensionId } = r;

        // ADJUSTMENTS FOR 9LEVELS ASSESSMENT
        if (assessment.id === ASSESSMENT_TYPES.NINE_LEVELS) {
          dimensionId = dimensionId.replace('cyan', 'turquoise');

          if (dimensionId.includes('_res_')) {
            return;
          }

          results[NINE_LEVELS_ORDER[dimensionId.split('levels_')[1].toLowerCase()]] = {
            id: dimensionId,
            name: r.dimensionName,
            range: getAssessmentRange(assessment.id, r.minValue, r.maxValue),
          };

          return;
        }

        results.push({
          id: dimensionId,
          name: r.dimensionName,
          range: getAssessmentRange(assessment.id, r.minValue, r.maxValue),
        });
      });

      const noDataText = assessmentProfile.status === ASSESSMENT_COMPLETED_STATUSES.NO_DATA
      && translate('ass_results_no_data_points_text');

      const footerText = role.referenceProfile.references && translate(
        'ass_results_data_points_footer_text',
        [ '{{datapoints}}', role.referenceProfile.references.length ],
      );

      switch (assessmentProfile.assessmentId) {
        case ASSESSMENT_TYPES.BIG5:
          referenceProfile.push(
            <Big5Result
              key={assessment.id}
              showReportButton={false}
              results={results}
              noDataText={noDataText}
              footerText={footerText}
            />,
          );
          break;
        case ASSESSMENT_TYPES.POTENTIAL:
          referenceProfile.push(
            <PotentialResult
              key={assessment.id}
              showDimensionReport={false}
              results={results}
              noDataText={noDataText}
              footerText={footerText}
            />,
          );
          break;
        case ASSESSMENT_TYPES.KEY_COMPETENCIES:
          referenceProfile.push(
            <CompetenciesResult
              key={assessment.id}
              assessmentType={ASSESSMENT_TYPES.KEY_COMPETENCIES}
              results={results}
              noDataText={noDataText}
              footerText={footerText}
            />,
          );
          break;
        case ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES:
          referenceProfile.push(
            <CompetenciesResult
              key={assessment.id}
              assessmentType={ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES}
              results={results}
              noDataText={noDataText}
              footerText={footerText}
            />,
          );
          break;
        case ASSESSMENT_TYPES.WORK_PREFERENCES:
          referenceProfile.push(
            <WorkPreferencesResult
              key={assessment.id}
              results={results}
              noDataText={noDataText}
              footerText={footerText}
            />,
          );
          break;
        case ASSESSMENT_TYPES.RMP:
          referenceProfile.push(
            <RmpResult
              key={assessment.id}
              showReportButton={false}
              results={results.sort((a, b) => sortAlphabetically(a.name, b.name))}
              noDataText={noDataText}
              footerText={footerText}
            />,
          );
          break;
        case ASSESSMENT_TYPES.NINE_LEVELS:
          referenceProfile.push(
            <NineLevelsResult
              key={assessment.id}
              showReportButton={false}
              results={results}
              noDataText={noDataText}
              footerText={footerText}
            />,
          );
          break;
        default:
          referenceProfile.push(
            <SkillsResult
              key={assessment.id}
              assessmentType={assessment.id}
              results={results}
              noDataText={noDataText}
              footerText={footerText}
            />,
          );
      }
    });

    return referenceProfile;
  };

  // RENDER ROLE LOADING SKELETON
  const renderRoleLoadingSkeleton = () => {
    const rows = [];

    for (let i = 0; i < 11; i += 1) {
      rows.push(
        <div key={i} className={styles.contentBlock}>
          <div className={styles.row} />
          <div className={styles.row} />
        </div>,
      );
    }

    return (
      <div className={styles.roleLoadingSkeleton}>
        <div className={styles.top} />
        { rows }
      </div>
    );
  };

  // RENDER: VacancyExternalSummary
  return (
    <div className={classNames(styles.vacancyExternalRoleDetails)}>
      <div className={styles.gridContainer}>
        { /* LEFT CONTENT */ }
        <div className={styles.leftContent}>
          <ProfileSummary vacancyBaseInfo={vacancy} showControls={false} teamClickable={false} />
        </div>

        { /* RIGHT CONTENT */ }
        <div className={styles.rightContent}>
          <div className='bluTypeXxs'>
            { `${translate('role_profile')} - ${vacancy.role ? vacancy.role.name : ''}` }
          </div>

          { !role && renderRoleLoadingSkeleton() }

          { Boolean(role?.description) && (
            <CollapsibleNext
              header={<span className='bluTypeLabel'>{ translate('vacancy_role_description') }</span>}
              size='L'
              withBorders
            >
              <TextEditor content={role.description} disabled />
            </CollapsibleNext>
          ) }

          { Boolean(role?.details) && (
            <CollapsibleNext
              header={<span className='bluTypeLabel'>{ translate('role_details_page_title') }</span>}
              size='L'
              withBorders
            >
              <TextEditor content={role.details} disabled />
            </CollapsibleNext>
          ) }

          { (role && renderReferenceProfile()) }
        </div>
      </div>

    </div>
  );
};

export default VacancyExternalRoleDetails;
