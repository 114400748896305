// REACT, STYLE, STORIES & COMPONENT
import React, { useState } from 'react';
import styles from './AttachmentsModalEdit.module.scss';

import { Button } from 'ui/basic';
import FormLink from './FormLink';
import FormDocument from './FormDocument';

// ASSETS
import { IconsSvg } from 'assets/icons';

// 3RD PARTY
import classNames from 'classnames';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';

// UTILS
import { useTranslate } from 'utils/translator';


// COMPONENT: AttachmentsModalEdit
const AttachmentsModalEdit = (props) => {
  // PROPS
  const {
    vacancyMode = false,
    attachments,
    onAttachmentEdit,
    onAttachmentDelete,
    onAttachmentAbort,
  } = props;

  const links = attachments.filter((a) => a.type === 'link');
  const files = attachments.filter((a) => a.type === 'file');
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isLinkValid, setIsLinkValid ] = useState(false);
  const [ activeLinkIndex, setActiveLinkIndex ] = useState(-1);
  const [ activeDocumentIndex, setActiveDocumentIndex ] = useState(-1);
  const [ isDocumentValid, setIsDocumentValid ] = useState(false);

  // SPECIAL HOOKS
  const translate = useTranslate();

  // FEATURE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS

  const onLinkAbortClick = (e) => {
    setActiveLinkIndex(-1);
    onAttachmentAbort();
    e.preventDefault();
  };

  const onLinkInputFocus = (index) => {
    setActiveLinkIndex(index);
    setActiveDocumentIndex(-1);
  };

  const onLinkValid = () => {
    setIsLinkValid(true);
  };

  const onLinkInvalid = () => {
    setIsLinkValid(false);
  };

  const linkDoneCallback = () => {
    setIsLoading(false);
    setActiveLinkIndex(-1);
  };

  const onLinkSubmit = (link) => {
    setIsLoading(true);
    onAttachmentEdit({ ...link, type: 'link' }, linkDoneCallback);
  };

  const onLinkDeleteClick = (e, link) => {
    setIsLoading(true);
    onAttachmentDelete({ ...link, type: 'link' }, linkDoneCallback);
    e.preventDefault();
  };

  const onDocumentAbortClick = (e) => {
    setActiveDocumentIndex(-1);
    onAttachmentAbort();
    e.preventDefault();
  };

  const onDocumentValid = () => {
    setIsDocumentValid(true);
  };

  const onDocumentInvalid = () => {
    setIsDocumentValid(false);
  };

  const documentDoneCallback = () => {
    setIsLoading(false);
    setActiveDocumentIndex(-1);
  };

  const onDocumentSubmit = (doc) => {
    setIsLoading(true);
    onAttachmentEdit({ ...doc, type: 'file' }, documentDoneCallback);
  };

  const onDocumentDeleteClick = (e, doc) => {
    setIsLoading(true);
    onAttachmentDelete({ ...doc, type: 'file' }, documentDoneCallback);
    e.preventDefault();
  };

  const onDocumentChange = (index, doc) => {
    setActiveDocumentIndex(index);
    setActiveLinkIndex(-1);

    if (doc.file === null && ((doc.name && doc.name.length !== 0)
      || (vacancyMode && [ null, undefined ].includes(doc.shareWithCandidates)))
    ) {
      setIsDocumentValid(true); // only the name was changed
    }
  };

  const onDocumentInputFocus = (index) => {
    setActiveDocumentIndex(index);
    setActiveLinkIndex(-1);
  };

  // RENDER: AttachmentsModalEdit
  return (
    <div className={styles.attachmentsModalEdit}>
      <h5>{ translate('candidate_attachments_edit_header') }</h5>

      { attachments.length === 0 && (
        <p>{ translate('candidate_attachments_edit_nothing_added') }</p>
      ) }

      { attachments.length !== 0 && (
        <ul>
          { links.map((link, index) => (
            <li key={link.url}>
              <FormLink
                vacancyMode={vacancyMode}
                link={link}
                isLoading={isLoading}
                onInputFocus={() => {
                  onLinkInputFocus(index);
                }}
                onValid={onLinkValid}
                onInvalid={onLinkInvalid}
                onSubmit={onLinkSubmit}
              >
                <div
                  className={classNames(
                    styles.editButtons,
                    activeLinkIndex === index
                      ? styles.editButtonsActive
                      : styles.editButtonsInactive,
                  )}
                >
                  <div className={styles.editButtonsWrapper}>
                    <Button
                      looks='secondary'
                      size='s'
                      disabled={isLoading}
                      onClick={(e) => {
                        onLinkAbortClick(e, index);
                      }}
                    >
                      { translate('candidate_attachments_edit_abort') }
                    </Button>

                    <Button
                      looks='primary'
                      size='s'
                      type='submit'
                      disabled={!isLinkValid || isLoading}
                    >
                      { translate('candidate_attachments_edit_save') }
                    </Button>
                  </div>
                </div>
              </FormLink>

              <button
                type='button'
                className={styles.deleteButton}
                onClick={(e) => {
                  onLinkDeleteClick(e, link);
                }}
              >
                <IconsSvg.Trash />
              </button>
            </li>
          )) }

          { files.map((file, index) => (
            <li key={file.id}>
              <FormDocument
                file={file}
                disabled={isLoading}
                isTitleShown
                vacancyMode={vacancyMode}
                onValid={onDocumentValid}
                onInvalid={onDocumentInvalid}
                onInputFocus={() => onDocumentInputFocus(index)}
                onChange={(doc) => onDocumentChange(index, doc)}
                onSubmit={onDocumentSubmit}
              >
                <div
                  className={classNames(
                    styles.editButtons,
                    activeDocumentIndex === index
                      ? styles.editButtonsActive
                      : styles.editButtonsInactive,
                  )}
                >
                  <div className={styles.editButtonsWrapper}>
                    <Button
                      looks='secondary'
                      size='s'
                      disabled={isLoading}
                      onClick={(e) => {
                        onDocumentAbortClick(e, index);
                      }}
                    >
                      { translate('candidate_attachments_edit_abort') }
                    </Button>

                    <Button
                      looks='primary'
                      size='s'
                      type='submit'
                      disabled={!isDocumentValid || isLoading}
                    >
                      { translate('candidate_attachments_edit_save') }
                    </Button>
                  </div>
                </div>
              </FormDocument>

              <button
                type='button'
                className={styles.deleteButton}
                onClick={(e) => {
                  onDocumentDeleteClick(e, file);
                }}
              >
                <IconsSvg.Trash />
              </button>
            </li>
          )) }
        </ul>
      ) }
    </div>
  );
};

export default AttachmentsModalEdit;
