import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import styles from './MyProfileOverview.module.scss';

import { useSelector, useDispatch } from 'react-redux';

import * as fromGarminSelectors from 'features/+garmin/store/garmin.selectors';
import * as fromGarminActions from 'features/+garmin/store/garmin.actions';

// 3RD PARTY
import classNames from 'classnames';
import { useHistory } from 'react-router';

// UTILS
import * as api from 'api';
import { apiNext } from 'apiNext';

// STORE
import {
  getCareerLevel,
  getCompanyEntryDate,
  getEmail,
  getFirstName,
  getLastName,
  getProfession,
  getUserPicture,
} from 'store/selectors/currentUser';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';
import * as fromWellBeingSelectors from 'store/selectors/wellBeing';
import * as fromSelectors from 'store/selectors/staticValues';
import * as fromAssessmentsSelectors from 'store/selectors/assessment';
import * as fromProfileSelectors from 'store/selectors/profile';
import {
  selectCapabilitiesNext, selectCompany,
  selectFeaturesNext,
  selectInstanceContainsBluquistFeatures, selectIsBalancedYou,
} from 'store/selectors/configuration';
import {
  getAssessmentResult, getCurrentUser,
  getWellBeingIndex, listAssessments,
  getProfile,
  listUserFlows,
} from 'store/actions';
import { selectSurveyParticipations } from 'store/selectors/surveys';
import { selectUserFlows } from 'store/selectors/flow';

// ASSETS
import { ReactComponent as HumanSitting } from 'assets/keyvisuals/sitting-human.svg';

// OTHER COMPONENTS
import AssessmentCarousel from 'ui/basic/containers/AssessmentCarousel/AssessmentCarousel';
import ShareProfileLinkModal from 'features/+employees/pages/EmployeeProfile/components/ShareProfileLinkModal';
import { CandidateProfile } from 'ui/basic/molecules/CandidateProfile';
import {
  EditCandidateProfilePanel,
  EditProfilePanel,
  Button,
  Profile,
  ButtonCircular,
  OptionsMenu,
  Modal, StoreNext, Card, MyProfileTeaser,
} from 'ui/basic';
import {
  Big5Result,
  CliftonResult,
  CompetenciesResult,
  ExportAssessmentResult,
  ModalAssessmentsConfiguration,
  NineLevelsResult,
  Peer360Result,
  PotentialResult,
  RmpResult,
  SkillsResult,
  WellBeingTeaserNext,
  WorkPreferencesResult,
} from 'ui/molecules';
import RmpImport from '../Overview/RmpImport';
import { TaskList } from 'features/+surveys/components/TaskList';
import AttachmentsCard from 'features/+candidates/pages/CandidateSummary/AttachmentsCard';
import AttachmentsModal from 'features/+candidates/pages/CandidateSummary/AttachmentsModal';

// UTILS
import { useTranslate } from 'utils/translator';
import { timestampToDate } from 'utils/dateTools';
import { getMappedCandidate } from 'utils/users';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { MAPPED_PLATFORM_ROLES } from 'utils/configuration/const/roles';
import { FlowCard } from 'features/+candidates/pages/CandidateSummary/FlowCard';

const OPTION_TYPES = {
  SHARE_PROFILE_LINK: 'SHARE_PROFILE_LINK',
  EXPORT_ASSESSMENTS_RESULTS: 'EXPORT_ASSESSMENTS_RESULTS',
  IMPORT_RMP: 'IMPORT_RMP',
  OPEN_AVAILABLE_ASSESSMENTS: 'OPEN_AVAILABLE_ASSESSMENTS',
};


const MyProfileOverview = () => {
  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const translate = useTranslate();
  const history = useHistory();

  // STATE
  const [ showEditProfilePage, setShowEditProfilePage ] = useState(false);

  const [ teasersPageLeftAvailable, setTeasersPageLeftAvailable ] = useState(false);
  const [ teasersPageRightAvailable, setTeasersPageRightAvailable ] = useState(false);

  // SELECTORS
  const me = useSelector(fromCurrentUserSelectors.getCurrentUser);
  const firstName = useSelector(getFirstName);
  const lastName = useSelector(getLastName);
  const email = useSelector(getEmail);
  const userPicture = useSelector(getUserPicture);
  const profession = useSelector(getProfession);
  const careerLevel = useSelector(getCareerLevel);
  const companyEntryDate = useSelector(getCompanyEntryDate);
  const profile = useSelector(fromProfileSelectors.getProfile);
  const userFlows = useSelector((state) => selectUserFlows(state, me.id));

  const capabilitiesNext = useSelector(selectCapabilitiesNext);
  const featuresNext = useSelector(selectFeaturesNext);
  const company = useSelector(selectCompany);
  const isBalancedYou = useSelector(selectIsBalancedYou);

  const careerLevelOptions = useSelector(fromSelectors.selectCareerLevelOptions);
  const assessments = useSelector(fromAssessmentsSelectors.selectAssessmentsTable);

  const containsBluquistFeatures = useSelector(selectInstanceContainsBluquistFeatures);
  const showStrengths = useSelector(fromAssessmentsSelectors.selectShowStrengths);
  const assessmentsFromStore = useSelector(fromAssessmentsSelectors.selectAssessments);
  const assessmentsFromStoreOpen = assessmentsFromStore
  .filter(fromAssessmentsSelectors.ASSESSMENT_FILTERS.noClifton)
  .filter(fromAssessmentsSelectors.ASSESSMENT_FILTERS.noUnavailable);

  const isCandidate = me.roleNext === 'candidate';

  // TEASERS
  const scrollableRef = useRef();

  // MODALS
  const [ rmpImportModalVisible, setRmpImportModalVisible ] = useState();
  const [ assessmentModalVisible, setAssessmentModalVisible ] = useState(false);
  const [ shareProfileLinkModalVisible, setShareProfileLinkModalVisible ] = useState();

  // GARMIN CONNECTION STATUS
  const garminAccountConnected = useSelector(fromGarminSelectors.selectGarminConnectionStatus);
  useEffect(() => {
    if (garminAccountConnected === null && featuresNext.garmin) {
      dispatch(fromGarminActions.getConnectionStatus());
    }
  }, [ dispatch, featuresNext, garminAccountConnected ]);

  useEffect(() => {
    if (!isCandidate || userFlows) {
      return;
    }
    dispatch(listUserFlows({ id: me.id }));
  }, [ dispatch, me.id, isCandidate, userFlows ]);

  // GARMIN USER SCORE: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const garminUserScore = useSelector(fromGarminSelectors.selectGarminUserScore);
  const [ garminUserScoreRequested, setGarminUserScoreRequested ] = useState(false);
  useEffect(() => {
    if (featuresNext.garmin
      && !garminUserScore
      && !garminUserScoreRequested
    ) {
      dispatch(fromGarminActions.getUserScore());
      setGarminUserScoreRequested(true);
    }
  }, [
    dispatch,
    featuresNext.garmin,
    garminUserScore,
    garminUserScoreRequested,
  ]);

  // UPDATE USER: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const [ updateUserConfirmationModalVisible, setUpdateUserConfirmationModalVisible ] = useState();
  const [ updateUserData, setUpdateUserData ] = useState();
  const [ updateUserProcessing, setUpdateUserProcessing ] = useState();
  const updateUser = () => {
    setUpdateUserProcessing(true);
    api.post('core/user/update', updateUserData)
    .then(({ ok, status }) => {
      setUpdateUserProcessing(false);
      if (ok && status === 200) {
        dispatch(getCurrentUser());
        setUpdateUserConfirmationModalVisible(false);
        setShowEditProfilePage(false);
      }
    })
    .catch((error) => {
      console.error(error.message);
      setUpdateUserProcessing(false);
    });
  };

  // WELL-BEING: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const myWellBeing = useSelector(fromWellBeingSelectors.getMyWellBeing);
  useEffect(() => {
    if (me.canAccessWellBeing && !myWellBeing) {
      dispatch(getWellBeingIndex());
    }
  }, [
    dispatch,
    me.canAccessWellBeing,
    myWellBeing,
  ]);

  // ASSESSMENTS: STATE, EFFECTS, STORE, METHODS, EVENT HANDLES, HELPERS, RENDERS
  const surveyParticipations = useSelector(selectSurveyParticipations);

  const [ assessmentResultRequested, setAssessmentResultRequested ] = useState(false);
  useEffect(() => {
    if (assessmentResultRequested || !assessmentsFromStore?.length) {
      return;
    }

    const byAssessment = assessmentsFromStore.find((a) => a.id === ASSESSMENT_TYPES.BALANCED_YOU);
    if (byAssessment?.completed) {
      dispatch(getAssessmentResult(ASSESSMENT_TYPES.BALANCED_YOU));
      setAssessmentResultRequested(true);
    }
  }, [
    dispatch,
    assessmentsFromStore,
    assessmentResultRequested,
  ]);

  const istAssessment = useSelector((state) => state.assessments.table[ASSESSMENT_TYPES.IST]);
  const istAssessmentResults = istAssessment?.results;
  const istVulnerable = istAssessmentResults?.find(({ id }) => id === 'ist_total')?.classification === 'vulnerable';
  useEffect(() => {
    if (!istAssessment) {
      return;
    }

    if (istAssessment.completed && !istAssessmentResults) {
      dispatch(getAssessmentResult(ASSESSMENT_TYPES.IST));
    }
  }, [
    dispatch,
    istAssessment,
    istAssessmentResults,
  ]);

  const [ assessmentsListRequested, setAssessmentsListRequested ] = useState(false);
  useEffect(() => {
    if (assessmentsListRequested) {
      return;
    }

    setAssessmentsListRequested(true);
    dispatch(listAssessments());
  }, [ dispatch, assessmentsListRequested ]);

  const getBalancedYouScore = () => {
    const byAssessment = assessments[ASSESSMENT_TYPES.BALANCED_YOU];

    if (!byAssessment || byAssessment.userAvailable === false) {
      return undefined;
    }

    const byAssessmentResults = byAssessment.results;
    if (byAssessmentResults) {
      return byAssessmentResults[byAssessmentResults.length - 1].result;
    }

    return undefined;
  };

  const [ tasks, setTasks ] = useState();
  const fetchTasks = useCallback(() => {
    api.get('/core/user/tasks')
    .then(({ ok, data = {} }) => {
      if (ok) {
        setTasks(data.tasks);
      }
    });
  }, []);

  useEffect(() => {
    fetchTasks();
  }, [ fetchTasks ]);

  // ATTACHMENTS
  const [ attachments, setAttachments ] = useState([]);
  const [ attachmentsLoading, setAttachmentsLoading ] = useState(false);
  const [ attachmentsModalVisible, setAttachmentsModalVisible ] = useState(false);
  const fetchAttachments = useCallback(() => {
    setAttachmentsLoading(true);
    apiNext.get('/core/user/attachments')
    .then((response) => {
      setAttachments(response.attachments);
    })
    .catch((error) => {
      console.error(error.message);
    })
    .finally(() => {
      setAttachmentsLoading(false);
    });
  }, [ ]);

  useEffect(() => {
    fetchAttachments();
  }, [ fetchAttachments ]);

  const onAttachmentEdit = (attachment, done, fail) => {
    let apiMethod = 'put';
    let body = attachment;

    if (attachment.type === 'file') {
      if (!attachment.file) {
        // only change the name of the edited document
        body = { type: 'file', id: attachment.id, name: attachment.name };
      } else {
        // upload the new file body
        const formData = new FormData();
        formData.append(attachment.name, attachment.file);
        apiMethod = 'put_binary';
        body = formData;
      }
    }

    api[apiMethod](`/core/user/attachments/${attachment.id}`, body)
    .then(({ ok, data = {} }) => {
      if (ok) {
        done();
        fetchAttachments();
      } else {
        fail(data.error.errorMessage);
        console.error(data.error.errorMessage);
      }
    })
    .catch((error) => {
      fail(error.message);
      console.error(error.message);
    });
  };

  const onAttachmentAdd = (attachment, done, fail) => {
    let body = attachment;

    if (attachment.type === 'file') {
      // don't send the file body yet but below in the PUT request
      body = {
        type: 'file',
        name: attachment.name.length !== 0 ? attachment.name : attachment.file.name,
      };
    }

    apiNext.post('/core/user/attachments', body)
    .then((response) => {
      // to upload a file we need to make the extra PUT request
      if (attachment.type === 'file') {
        body = { type: 'file', file: attachment.file, id: response.id };

        onAttachmentEdit(body, done, fail);
      } else {
        done();
      }

      fetchAttachments();
    })
    .catch((error) => {
      fail(error.message);
    });
  };

  const onAttachmentDelete = (attachment, done) => {
    apiNext.delete(`/core/user/attachments/${attachment.id}`)
    .then(() => {
      done();
      fetchAttachments();
    })
    .catch((error) => {
      done(true);
      console.error(error.message);
    });
  };

  const getMenuOptions = () => {
    const options = [];

    if (assessmentsFromStore.some((a) => a.completed && assessments[a.id].userAvailable !== false)) {
      options.push({
        value: OPTION_TYPES.EXPORT_ASSESSMENTS_RESULTS,
        label: translate('export_assessments_results'),
      });
    }

    if (!isCandidate) {
      options.unshift({
        value: OPTION_TYPES.SHARE_PROFILE_LINK,
        label: translate('manage_profile_links'),
      });
    }

    if (featuresNext.rmp
      && !assessmentsFromStore.find((a) => a.id === ASSESSMENT_TYPES.RMP)?.completed
      && company.allowAssessmentImport_rmp) {
      options.push({
        value: OPTION_TYPES.IMPORT_RMP,
        label: translate('rmp_import'),
      });
    }

    if ([
      MAPPED_PLATFORM_ROLES.COMPANY_ADMINISTRATOR,
      MAPPED_PLATFORM_ROLES.ASSESSMENT_MANAGER,
    ].includes(me.role)) {
      options.unshift({
        value: OPTION_TYPES.OPEN_AVAILABLE_ASSESSMENTS,
        label: translate('available_assessments_option_lbl'),
      });
    }

    return options;
  };

  const [ diagramType, setDiagramType ] = useState();
  const [ diagramRenderProcessing, setDiagramRenderProcessing ] = useState();
  const openExportModal = (diagramTypeParam) => {
    setDiagramType(diagramTypeParam);
    if (!profile) {
      dispatch(getProfile(me.id));
      setDiagramRenderProcessing(true);
    }
  };

  useEffect(() => {
    if (profile) {
      setDiagramRenderProcessing(false);
    }
  }, [ profile ]);

  // RENDER: Result component
  const renderResultComponent = ((assessmentType, options = {}) => {
    const { exportView, diagramId } = options;
    const assessment = profile.assessmentResults.find((p) => p.assessment === assessmentType);
    const { sharedWithUser } = assessment ?? {};

    switch (assessmentType) {
      case ASSESSMENT_TYPES.BIG5:
        return (
          <div id={diagramId}>
            <Big5Result
              results={assessment.result.results}
              showExportIcon
              exportView={exportView}
              userId={me.id}
              sharedWithUser={sharedWithUser}
            />
          </div>
        );
      case ASSESSMENT_TYPES.POTENTIAL:
        return (
          <div id={diagramId}>
            <PotentialResult
              showCallout
              results={assessment.result.results}
              showExportIcon
              exportView={exportView}
              userId={me.id}
              sharedWithUser={sharedWithUser}
            />
          </div>
        );
      case ASSESSMENT_TYPES.WORK_PREFERENCES:
        return (
          <div id={diagramId}>
            <WorkPreferencesResult
              results={assessment.result.results}
              showExportIcon
              exportView={exportView}
              userId={me.id}
              sharedWithUser={sharedWithUser}
            />
          </div>
        );
      case ASSESSMENT_TYPES.KEY_COMPETENCIES:
      case ASSESSMENT_TYPES.LEADERSHIP_COMPETENCIES:
        return (
          <div id={diagramId}>
            <CompetenciesResult
              assessmentType={assessmentType}
              results={assessment.result.results}
              showExportIcon
              exportView={exportView}
              userId={me.id}
              sharedWithUser={sharedWithUser}
            />
          </div>
        );
      case ASSESSMENT_TYPES.RMP:
        return (
          <div id={diagramId}>
            <RmpResult
              results={assessment.result.results}
              showExportIcon
              exportView={exportView}
              userId={me.id}
              sharedWithUser={sharedWithUser}
            />
          </div>
        );
      case ASSESSMENT_TYPES.NINE_LEVELS:
        return (
          <div id={diagramId}>
            <NineLevelsResult
              results={assessment.result.results}
              signatureValues={assessment.result.signatureValues}
              showExportIcon
              exportView={exportView}
              userId={me.id}
              sharedWithUser={sharedWithUser}
            />
          </div>
        );
      default: {
        const thisAssessment = assessmentsFromStore.find((a) => a.id === assessmentType);
        if (thisAssessment?.render_results === 'clifton') {
          return (
            <div id={diagramId}>
              <CliftonResult strengths={assessment.result.results} />
            </div>
          );
        }

        if (thisAssessment?.peerAssessment) {
          return (
            <div id={diagramId}>
              <Peer360Result
                assessmentData={assessmentsFromStore.find((a) => a.id === assessmentType)}
                assessmentResults={assessment?.result?.results}
                showExportIcon
                exportView={exportView}
                assessmentType={assessmentType}
                userId={me.id}
                sharedWithUser={sharedWithUser}
              />
            </div>
          );
        }

        return (
          <div id={diagramId}>
            <SkillsResult
              assessmentType={assessmentType}
              results={assessment.result.results}
              signatureValues={assessment.result.signatureValues}
              showExportIcon
              exportView={exportView}
              userId={me.id}
              sharedWithUser={sharedWithUser}
            />
          </div>
        );
      }
    }
  });

  // RENDER: Assessment teasers (or placeholders)
  const renderAssessmentTeasers = () => {
    // TODO: add filtering logic for recurring assessments

    // No available assessments/surveys
    if (assessmentsFromStoreOpen.length === 0 && surveyParticipations?.length === 0) {
      return (
        <div className={styles.noData}>
          <div className='bluTypeXs'>{ translate('no_data') }</div>
          <div className={classNames('bluTypeCopy', 'marginTopXxs')}>
            { translate('myprofile_overview_no_data') }
          </div>
          <HumanSitting />
        </div>
      );
    }

    // Some assessments/surveys can be taken
    if (assessmentsFromStoreOpen.some((a) => !a.completed || !a.locked) || surveyParticipations?.length) {
      return (
        <div className={styles.assessmentTeasers}>
          <div className={styles.titleBlock}>
            <div className='bluTypeXxs'>
              { assessmentsFromStoreOpen.every((a) => !a.completed)
                ? translate('ep_headline_unassessed', [ '{{username}}', firstName ])
                : translate('ep_headline_partially_assessed', [ '{{username}}', firstName ]) }
            </div>

            <div className={styles.scrollingButtons}>
              <ButtonCircular
                icon='ArrowLeft'
                looks='primary'
                disabled={!teasersPageLeftAvailable}
                onClick={() => { scrollableRef.current.pageLeft(); }}
              />
              <ButtonCircular
                icon='ArrowRight'
                looks='primary'
                disabled={!teasersPageRightAvailable}
                onClick={() => { scrollableRef.current.pageRight(); }}
              />
            </div>
          </div>

          <AssessmentCarousel
            scrollableRef={scrollableRef}
            assessments={assessmentsFromStoreOpen}
            surveyParticipations={surveyParticipations}
            onPaginationAvailabilities={([ pageLeftAvailable, pageRightAvailable ]) => {
              setTeasersPageLeftAvailable(pageLeftAvailable);
              setTeasersPageRightAvailable(pageRightAvailable);
            }}
            setRmpImportModalVisible={setRmpImportModalVisible}
          />
        </div>
      );
    }

    // All assessments completed
    return (
      <>
        <div className='bluTypeXs'>
          { translate('main_navigation_item_1') }
        </div>
        { showStrengths && (
          <MyProfileTeaser
            user={me}
            showStrengths
            buttonTitle={isBalancedYou
              ? (translate('mind_journey__ind_profile_teaser_btn'))
              : (translate('see_strength_profile_btn'))}
          />
        ) }
      </>
    );
  };

  // RENDER: MyProfileOverview
  return (
    <div className={styles.myProfileOverview}>
      <div className={styles.gridContainer}>
        <div className={styles.personalInfo}>
          <div className={classNames(
            styles.subBlock,
            { [styles.sticky]: !isCandidate },
          )}
          >
            { isCandidate
              ? <CandidateProfile candidate={getMappedCandidate(me)} />
              : (
                <Profile
                  roleClickable={capabilitiesNext.rolesView}
                  user={{
                    id: me.id,
                    name: `${firstName} ${lastName}`,
                    email,
                    userPicture,
                    position: profession,
                    careerLevel: careerLevelOptions.find((cl) => cl.value === careerLevel)
                      ? careerLevelOptions.find((cl) => cl.value === careerLevel).label
                      : '',
                    since: companyEntryDate && timestampToDate(companyEntryDate),
                    status: {
                      percentage: 10,
                    },
                  }}
                />
              ) }

            { /* ATTACHMENTS */ }
            { (capabilitiesNext.employeesView || attachments.length > 0) && (
              <div className='marginTopXs'>
                <AttachmentsCard
                  attachments={attachments}
                  isLoading={attachmentsLoading}
                  readOnly={!capabilitiesNext.employeesView}
                  onAddEditClick={() => setAttachmentsModalVisible(true)}
                  getDownloadParams={(file) => ([ `/core/user/attachments/${file.id}/file` ])}
                />
              </div>
            ) }

            <div className={styles.buttons}>
              <Button
                looks='secondary'
                size='S'
                onClick={() => setShowEditProfilePage(true)}
              >
                { translate('ps_button_editprofile') }
              </Button>

              { (containsBluquistFeatures && !isCandidate) && (
                <OptionsMenu
                  withBackground
                  flyoutPosition='right'
                  options={getMenuOptions()}
                  onClick={(value) => {
                    switch (value) {
                      case OPTION_TYPES.SHARE_PROFILE_LINK:
                        setShareProfileLinkModalVisible(true);
                        break;
                      case OPTION_TYPES.IMPORT_RMP:
                        setRmpImportModalVisible(true);
                        break;
                      case OPTION_TYPES.OPEN_AVAILABLE_ASSESSMENTS:
                        setAssessmentModalVisible(true);
                        break;
                      case OPTION_TYPES.EXPORT_ASSESSMENTS_RESULTS:
                        openExportModal('all');
                        break;
                      default:
                    }
                  }}
                />
              ) }
            </div>
          </div>
        </div>

        <div className={styles.assessmentsInfo}>
          { renderAssessmentTeasers() }

          { userFlows?.map((userFlow) => (
            <FlowCard
              key={userFlow.id}
              user={me}
              userFlow={userFlow}
            />
          )) }

          <WellBeingTeaserNext
            bodyBattery={garminUserScore?.bodyBattery}
            wellBeing={myWellBeing?.wellbeing}
            balancedYou={getBalancedYouScore()}
            ist={istAssessmentResults && { vulnerable: istVulnerable }}
          />

          <TaskList
            assessments={assessmentsFromStoreOpen}
            tasks={tasks}
          />

          { (garminUserScore?.bodyBattery || myWellBeing?.wellbeing || getBalancedYouScore()) && (
            <Card>
              <span className='bluTypeLabelL'>
                { translate('rec_teaser_title') }
              </span>
              <div className={styles.flex}>
                <div className='bluTypeCopy'>
                  { translate('rec_teaser_copy') }
                </div>
                <Button
                  size='S'
                  looks='secondary'
                  onClick={() => history.push('/my-profile/recommendations')}
                >
                  { translate('rec_teaser_btn') }
                </Button>
              </div>
            </Card>
          ) }

        </div>

        { showEditProfilePage && (
          isCandidate
            ? (
              <StoreNext>
                <EditCandidateProfilePanel
                  showConfirmModal={(data) => {
                    setUpdateUserData(data);
                    setUpdateUserConfirmationModalVisible(true);
                  }}
                  onClose={(hasUpdatedChanges) => {
                    if (hasUpdatedChanges) {
                      dispatch(getCurrentUser());
                    }
                    setShowEditProfilePage(false);
                  }}
                />
              </StoreNext>
            ) : (
              <EditProfilePanel
                onClose={() => setShowEditProfilePage(false)}
              />
            )
        ) }

        { /* RMP IMPORT */ }
        { rmpImportModalVisible && (
          <RmpImport onClose={() => setRmpImportModalVisible(false)} />
        ) }

        { /* SHARE PROFILE LINK MODAL */ }
        { shareProfileLinkModalVisible && (
          <ShareProfileLinkModal
            passwordRequired={false}
            showPersonalLinks
            showHealthAssessments={[ 'bluquist_health', 'bluquist_health_ist' ].includes(company.plan)}
            onClose={() => setShareProfileLinkModalVisible(false)}
          />
        ) }

        { /* UPDATE USER CONFIRMATION MODAL */ }
        { updateUserConfirmationModalVisible && (
          <Modal
            header={translate('unsaved_changes_modal_header')}
            redButtonTitle={translate('unsaved_changes_modal_discard_btn')}
            redButtonDisabled={updateUserProcessing}
            secondaryButtonTitle={translate('unsaved_changes_modal_save_btn')}
            secondaryButtonDisabled={updateUserProcessing}
            onClose={(closeButtonClicked) => {
              setShowEditProfilePage(false);
              setUpdateUserConfirmationModalVisible(false);
              if (closeButtonClicked) {
                updateUser();
              }
            }}
          >
            { translate('unsaved_changes_modal_copy') }
          </Modal>
        ) }

        { /* ASSESSMENTS CONFIGURATION MODAL */ }
        { assessmentModalVisible && (
          <ModalAssessmentsConfiguration
            userId={me.id}
            showAssessmentResetHandle
            onClose={() => setAssessmentModalVisible(false)}
            onUpdate={() => {
              fetchTasks();
              dispatch(listAssessments());
            }}
          />
        ) }

        { attachmentsModalVisible && (
          <AttachmentsModal
            attachments={attachments}
            onClose={() => setAttachmentsModalVisible(false)}
            onAttachmentAdd={onAttachmentAdd}
            onAttachmentEdit={onAttachmentEdit}
            onAttachmentDelete={onAttachmentDelete}
            introKey='profile_attachments_add_intro'
          />
        ) }

        { /* EXPORT MODAL */ }
        { diagramType && (
          <ExportAssessmentResult
            diagramType={diagramType}
            assessmentsIds={assessmentsFromStore.map(({ id }) => id)}
            user={{ firstName, lastName }}
            diagramRenderProcessing={diagramRenderProcessing}
            onClose={() => setDiagramType()}
          >
            { profile?.assessmentResults
            .filter(({ result }) => result)
            .map(({ assessment }) => (
              <div
                id={assessment}
                key={`result-${assessment}`}
                className='marginTopXs'
              >
                { renderResultComponent(
                  assessment,
                  {
                    exportView: true,
                    diagramId: `export-${assessment}`,
                  },
                ) }
              </div>
            )) }
          </ExportAssessmentResult>
        ) }
      </div>
    </div>
  );
};

export default MyProfileOverview;
