// REACT, STYLE, STORIES & COMPONENT
import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
// import styles from './CustomAssessmentPage.module.scss';

// ASSETS
// import {IconsSvg} from 'assets/icons';

// 3RD PARTY
// import classNames from 'classnames';
import { useHistory, useParams } from 'react-router';

// OTHER COMPONENTS
// import { Button, InputNext } from 'ui/basic';
import { AssessmentNext } from 'ui/molecules/AssessmentNext';
import { Loading } from 'ui/basic';
import { SinglePageAssessment } from 'ui/molecules/SinglePageAssessment';

// UTILS
import { apiNext } from 'apiNext';
import * as api from 'api';
import {
  initialiseAssessment,
  handleAssessmentAnswer,
} from 'utils/assessment';
import { returnToUrl } from 'features/+coachHub/utils/returnUrl';
import { getFullName } from 'utils/users';
import {
  getIsExternalSystemIntegrationSupported,
  replaceCoachHubTarget,
} from 'features/+coachHub/utils/localStorage';

// STORE
import { useDispatch, useSelector } from 'react-redux';
import * as fromCurrentUserSelectors from 'store/selectors/currentUser';
import { listAssessments } from 'store/actions';
import { selectFeaturesNext } from 'store/selectors/configuration';

// CONFIG & DATA
const Config = {
  hurryDelayForFreeTextMs: 90 * 1000,
};

let externalSystemSupportedOuter;

// COMPONENT: CustomAssessmentPage
const CustomAssessmentPage = (props) => {
  // PROPS
  const {
    assessmentId,
    user,
    onError,
    peerFor = '',
    shareToken = '',
  } = props;

  // SPECIAL HOOKS
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  // STORE
  const currentLanguage = useSelector((state) => state.localisation?.currentLanguage);
  const userFromStore = useSelector(fromCurrentUserSelectors.getCurrentUser);
  const featuresNext = useSelector(selectFeaturesNext);

  const peerForInternal = useRef(peerFor);

  const externalSystemSupported = getIsExternalSystemIntegrationSupported();

  if (externalSystemSupportedOuter === undefined) {
    // assign the value of 'externalSystemSupported' to an outer value
    // so that it doesn't get changed on local storage clear (via returnToUrl)
    externalSystemSupportedOuter = externalSystemSupported;
  }

  const [ userInternal, setUserInternal ] = useState();
  useEffect(() => {
    if (userInternal?.id) {
      return;
    }

    setUserInternal(user || userFromStore);
  }, [ userInternal, userFromStore, user ]);

  // GET ASSESSMENT DATA
  const [ loadingAssessment, setLoadingAssessment ] = useState(false);
  const [ assessmentIdInternal, setAssessmentIdInternal ] = useState();
  const [ assessmentQuestions, setAssessmentQuestions ] = useState();
  const [ configOverride, setConfigOverride ] = useState();
  const [ requiredQuestions, setRequiredQuestions ] = useState();
  const [ openQuestions, setOpenQuestions ] = useState();
  const [ assessmentCompleted, setAssessmentCompleted ] = useState();

  const [ type, setType ] = useState('custom');

  const [ observerMode, setObserverMode ] = useState(false);

  const [ isPeerAssessment, setIsPeerAssessment ] = useState();
  const [ peerAssessmentIsSetup, setPeerAssessmentIsSetup ] = useState();
  const [ peerAssessmentForSelfIsCompleted, setPeerAssessmentForSelfIsCompleted ] = useState();
  const [ externalSystemAssessmentError, setExternalSystemAssessmentError ] = useState('');
  const init = useCallback((customAssessmentId) => {
    initialiseAssessment({
      userId: userInternal?.id,
      assessmentId: customAssessmentId,
      replacements: {
        username: (assessment) => {
          if (peerForInternal.current) {
            return peerForInternal.current;
          }
          if (assessment.asPeer) {
            return getFullName(assessment.asPeer.forUser);
          }
          return '';
        },
      },
      handleExit: (assessmentData = {}) => {
        if (externalSystemSupported) {
          setExternalSystemAssessmentError(assessmentData.locked ? 'assessment_completed' : 'assessment_error');
          return;
        }
        history.push('/');
      },
      shareToken,
    })
    .then(({ questions, assessment = {}, prevAnswers }) => {
      setIsPeerAssessment(assessment.peerAssessment);
      setObserverMode(assessment.observerMode);

      // Peer360 setup
      if (assessment.peerAssessment) {
        setType('customPeer360');

        setPeerAssessmentForSelfIsCompleted(assessment.peerProgress?.self?.progress >= 100);
        setPeerAssessmentIsSetup(Boolean(assessment.peerProgress));

        if (assessment.asPeer?.forUser) {
          peerForInternal.current = getFullName(assessment.asPeer.forUser);
        }

        // For completed or unset peer360 assessments,
        // assessment-reminder mailType must redirect to setup page.
        const isAssessmentSet = assessment.asPeer || assessment.peerProgress || params.shareToken;
        if (!externalSystemSupported && (assessment.peerProgress?.self?.completedAt || !isAssessmentSet)) {
          history.replace('/'); // for goBack
          history.push(`/assessments/${assessment.id}/peer/settings`);
        }

        if (!peerForInternal.current && assessment.peerOnly) {
          history.push('/');
        }
      }

      setConfigOverride({
        peerFor: peerForInternal.current,
        asPeer: assessment.asPeer,
        hasBqAccount: userInternal?.hasBqAccount,
        peerProgress: assessment.peerProgress,

        customAssessmentId: assessment.id,
        title: assessment.title,
        // description: assessment.description,
        description: assessment.info_texts?.long_description,

        allowBackNavigation: assessment.free_navigation,
        // canContinueLater: true,
        // modalHurryDelay: 5 * 1000,
        canContinueLater: assessment.multi_session,
        modalHurryDelay: assessment.time_to_reminder * 1000,

        // modalHelpContent: '[blu-markdown] **modal help content**',
        // guidanceContent: '[blu-markdown] **guidance content**',
        modalHelpContent: (peerForInternal.current ? assessment.customContent?.question_help_peer : null)
        ?? assessment.info_texts?.question_help,
        guidanceContent: assessment.info_texts?.guidance_text,
        customContent: assessment.customContent,

        allowAnswerSkip: assessment.peerAllowSkip && Boolean(peerForInternal.current || assessment.asPeer),
        managerControlled: assessment.managerControlled,
        containsConditionalQuestions: questions?.some((question) => Boolean(question.conditional)),

        progress: assessment.progress,
        prevAnswers,
      });

      if (!questions || !assessment || assessment.error) {
        onError?.(assessment.error);
      }

      // activate answer options
      const inflatedQuestions = (questions ?? []).map((question) => ({
        ...question,
        useAnswerOptions: true,
        modalHurryDelay: question.type === 'free-text' ? Config.hurryDelayForFreeTextMs : undefined,
      }));
      setAssessmentQuestions(inflatedQuestions);

      const requiredQuestionsInternal = inflatedQuestions
      .filter(({ answerRequired }) => answerRequired)
      .map(({ id }) => id);
      setRequiredQuestions(requiredQuestionsInternal);
    });
  }, [
    shareToken,
    history,
    userInternal,
    onError,
    params,
    externalSystemSupported,
  ]);

  const [ newLanguageData, setNewLanguageData ] = useState();
  useEffect(() => {
    const customAssessmentId = assessmentId || params.customAssessmentId;

    if (!localStorage.getItem('langChangedForExternalAssessment')
      || !userInternal?.id
      || !customAssessmentId
    ) {
      return;
    }

    localStorage.removeItem('langChangedForExternalAssessment');

    Promise.all([
      api.get(`/core/assessments/${assessmentId}/1/questions`),
      api.get(`/core/assessments/${assessmentId}`),
    ])
    .then(([
      { ok: questionsOk, data: questionsData },
      { ok: assessmentOk, data: assessmentData },
    ]) => {
      if (questionsOk && assessmentOk) {
        setNewLanguageData({
          questionsData,
          replacements: {
            username: () => peerForInternal.current,
          },
          infoTexts: {
            title: assessmentData.title,
            description: assessmentData.info_texts?.long_description,
            guidanceContent: assessmentData.info_texts?.guidance_text,
            modalHelpContent: (peerForInternal.current ? assessmentData.customContent?.question_help_peer : null)
            ?? assessmentData.info_texts?.question_help,
          },
          customContent: assessmentData.customContent,
        });
      }
    });
  }, [
    params.customAssessmentId,
    assessmentId,
    currentLanguage,
    userInternal,
  ]);

  // get assessment
  useEffect(() => {
    const customAssessmentId = assessmentId || params.customAssessmentId;

    if (!userInternal?.id || !customAssessmentId || loadingAssessment) {
      return;
    }

    setAssessmentIdInternal(customAssessmentId);
    setLoadingAssessment(true);

    init(customAssessmentId);
  }, [
    init,
    assessmentId,
    params,
    history,
    loadingAssessment,
    userInternal,
    onError,
  ]);

  // METHODS

  // HANDLES
  const handleCancel = () => {
    // for peers no need to re-fetch the assessments
    if (!peerForInternal.current) {
      dispatch(listAssessments());
    }

    if (externalSystemSupported) {
      if (isPeerAssessment && !peerForInternal.current) {
        replaceCoachHubTarget('manage-assessment', `/assessments/${params.customAssessmentId}/peer/settings`);
      } else {
        returnToUrl({
          urlFromStorage: true,
          getParams: {
            status: 'assessment_canceled',
            assessment: assessmentId,
          },
        });
      }
      return;
    }

    history.goBack();
  };

  const handleFinish = () => {
    if (externalSystemSupported && !peerForInternal.current) {
      const isNotDone = Object.entries(configOverride.peerProgress ?? {})
      .some(([ k, v ]) => k !== 'self' && v.progress < 100);
      if (isNotDone) {
        returnToUrl({
          urlFromStorage: true,
          getParams: {
            status: 'assessment_completed',
            assessment: params.customAssessmentId,
            results_available: false,
          },
        });
      } else {
        replaceCoachHubTarget('profile', '/my-profile');
      }
      return;
    }

    history.push('/my-profile');
  };

  const [ waitApiRequest, setWaitApiRequest ] = useState(false);
  const handleAnswer = (answer) => {
    if (configOverride.containsConditionalQuestions) {
      setWaitApiRequest(true);
    }

    const answerInternal = { ...answer };
    const overrideAnswer = Array.isArray(answer.answer);

    if (overrideAnswer) {
      answerInternal.answer = { options: answerInternal.answer };
    }

    handleAssessmentAnswer(answerInternal, assessmentIdInternal, false, overrideAnswer)
    .then((response = {}) => {
      setRequiredQuestions(response?.requiredQuestions);
      setOpenQuestions(response?.openQuestions);
      setAssessmentCompleted(response?.assessmentCompleted);

      setWaitApiRequest(false);
    });
  };

  const handleAllAnswers = (answers, setFinishable) => {
    setFinishable(true);

    // for peers no need to re-fetch the assessments
    if (!peerForInternal.current) {
      dispatch(listAssessments());
    }
  };

  if (externalSystemSupportedOuter && !peerForInternal.current) {
    if (isPeerAssessment === undefined) {
      return <Loading visible />;
    }

    if (isPeerAssessment && peerAssessmentIsSetup === false) {
      returnToUrl({
        urlFromStorage: true,
        getParams: {
          status: 'error',
          error_type: 'assessment_not_set_up',
        },
      });
      return null;
    }

    if (isPeerAssessment && peerAssessmentForSelfIsCompleted) {
      returnToUrl({
        urlFromStorage: true,
        getParams: {
          status: 'assessment_completed',
          assessment: assessmentId,
        },
      });
      return null;
    }

    // mostly the case for non-peer custom assessments
    if (externalSystemAssessmentError) {
      returnToUrl({
        urlFromStorage: true,
        getParams: {
          status: externalSystemAssessmentError,
          assessment: assessmentId,
        },
      });
      return null;
    }
  }

  if (observerMode) {
    return (
      <SinglePageAssessment
        assessment={configOverride}
        questions={assessmentQuestions}
        peerFor={peerForInternal.current}
      />
    );
  }

  // RENDER: CustomAssessmentPage
  return (
    <AssessmentNext
      type={type}
      externalAssessmentToken={shareToken}
      questions={assessmentQuestions}
      requiredQuestions={requiredQuestions}
      openQuestions={openQuestions}
      assessmentCompleted={assessmentCompleted}
      newLanguageData={newLanguageData}
      userId={userInternal?.id}
      configOverride={configOverride}
      freeTextAiAssistanceEnabled={Boolean(featuresNext?.freeTextAiAssistance)}
      waitApiRequest={waitApiRequest}
      onCancel={handleCancel}
      onFinish={handleFinish}
      onAnswer={handleAnswer}
      onAllAnswers={handleAllAnswers}
    />
  );
};

export default CustomAssessmentPage;
