// REACT, STYLE, STORIES & COMPONENT
import React, {
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
  forwardRef,
  useRef,
  useContext,
} from 'react';
import styles from './Scrollable.module.scss';

// ASSETS

// STORE

// 3RD PARTY
import classnames from 'classnames';
import smoothscroll from 'smoothscroll-polyfill';

// OTHER COMPONENTS
import { Pagination } from 'ui/basic/containers/Pagination';

// UTILS
import { useDebounce, useWindowWidth } from 'utils/hooks';

// CONTEXTS
// eslint-disable-next-line import/no-cycle
import { LayoutContext } from 'features/framework/components/MainLayout/MainLayout';

// CONFIG & DATA
const CONFIG = {
  dragSnapThreshold: 25,
  startRightDelay: 500,
};

// SETUP
// kick off the polyfill!
smoothscroll.polyfill();

// COMPONENT: Scrollable
const Scrollable = (props, ref) => {
  // PROPS
  const {
    children,
    // layout
    fade, fadeWhite,
    noMargins,

    chartMargins, startRight, // startRight also fades in
    scroll, scrollOnClick,
    // scrollbar, // we don't need it
    drag,
    onClickBlock = () => {},

    // pagination
    pagination,
    showPaginationBubbles,
    showPaginationNumbers,
    showPagerButtons,
    onPaginationAvailabilities = () => {},

    refresh, // updates DomDerivedVars on change
    // onDomDerivedVars = () => {},

    reset,

    pageMargin = 0, // px
    breakout = 0, // px
  } = props;

  // COMPONENT/UI STATE and REFS
  // BASICS
  const [ scrollContainerNode, setScrollContainerNode ] = useState(null);
  // PAGINATION
  const [ pageWidth, setPageWidth ] = useState(0);
  const [ pagePosition, setPagePosition ] = useState(0);
  const [ pageCount, setPageCount ] = useState(0);
  const [ maxScroll, setMaxScroll ] = useState(0);
  // DRAG
  const [ dragging, setDragging ] = useState(false);
  const [ dragStartX, setDragStartX ] = useState(0);
  const [ dragStartScroll, setDragStartScroll ] = useState(0);
  // startRight
  const [ startedRight, setStartedRight ] = useState(false);
  const [ startedRightDone, setStartedRightDone ] = useState(false);

  // pagination ref
  const paginationRef = useRef(null);

  // STORE HOOKS

  // EXTERNAL EFFECTS
  const windowWidth = useWindowWidth();
  const debouncedWindowWidth = useDebounce(windowWidth, 250);
  const { mainNavIsOpenDebounced } = useContext(LayoutContext) || {};

  // update scrollLeft based on pagePosition and pageWidth

  const [ extraPaddingsForFadedScrollable, setExtraPaddingsForFadedScrollable ] = useState(false);
  useEffect(() => {
    // console.log('-- update scrollLeft on pagePosition etc. --');

    if (scrollContainerNode) {
      if (fade || fadeWhite) {
        setExtraPaddingsForFadedScrollable(false);
        const scrollContainerChildren = Array.from(scrollContainerNode.children);
        const childrenTotalWidth = scrollContainerChildren.reduce((current, acc) => current + acc.clientWidth, 0);
        if (childrenTotalWidth > scrollContainerNode.clientWidth) {
          setExtraPaddingsForFadedScrollable(true);
        }
      }

      // startRight: set pagePosition to pageCount
      // scroll immediately for startRight, scroll smooth for everything else
      const newScroll = {};
      if (startRight && !startedRight) {
        setStartedRight(true);
        setTimeout(() => {
          setPagePosition(pageCount - 1);
          newScroll.left = (pageCount - 1) * pageWidth;

          scrollContainerNode.scroll(newScroll);
          setStartedRightDone(true);
        }, CONFIG.startRightDelay);
      } else {
        const scrollLeft = pagePosition * pageWidth;
        newScroll.left = breakout && scrollLeft > maxScroll
          ? maxScroll
          : scrollLeft;
        newScroll.behavior = 'smooth';
        scrollContainerNode.scroll(newScroll);
      }
    }
  }, [
    pagePosition,
    pageWidth,
    pageCount,
    maxScroll,
    scrollContainerNode,
    startRight,
    startedRight,
    breakout,
    fade,
    fadeWhite,
  ]);

  useEffect(() => {
    setStartedRight(false);
  }, [ reset, refresh ]);

  const updateDOMDerivedVars = useCallback((node) => {
    // console.log('-- update DOMDerivedVars --');

    const newViewWidth = node.clientWidth;
    const newPageWidth = newViewWidth - 2 * breakout + pageMargin;
    const newContentWidth = node.scrollWidth - 2 * breakout;
    const newMaxScroll = newContentWidth - newPageWidth;
    setMaxScroll(newMaxScroll);
    setPageWidth(newPageWidth);

    const { scrollLeft } = node;

    const newPageCount = Math.ceil(newContentWidth / newPageWidth);
    setPageCount(newPageCount);

    const newPagePosition = Math.round(scrollLeft / newPageWidth);
    // console.log('updateDOMDerivedVars:newPagePosition', newPagePosition);
    setPagePosition(newPagePosition);

    // CAUSES RERENDER!
    // onDomDerivedVars({
    //   viewWidth,
    //   pageWidth,
    //   contentWidth
    // });
  }, [ breakout, /* onDomDerivedVars, */ pageMargin ]);

  // updateDomDerivedVars when windowWidth changes
  useEffect(() => {
    // console.log('-- update on window.width changes --');
    if (scrollContainerNode) {
      // console.log('effect:updateDOMDerivedVars');
      updateDOMDerivedVars(scrollContainerNode);
    }
  }, [ debouncedWindowWidth, refresh, mainNavIsOpenDebounced, scrollContainerNode, updateDOMDerivedVars ]);


  // experiment: allow scrolling with snapping!
  // experiment: allow scroll and pagination
  // useEffect(() => {
  //   if (!scrollContainerNode) return;
  //   // detect end of scrolling
  //   let isScrolling, isStarted, scrollLeftStart;
  //   // listen for scroll events
  //   scrollContainerNode.addEventListener('scroll',  () => {
  //     console.log('scroll');
  //     if (!isStarted) {
  //       isStarted = true;
  //       scrollLeftStart = scrollContainerNode.scrollLeft;
  //     }
  //     // clear our timeout throughout the scroll
  //     window.clearTimeout( isScrolling );

  //     // set a timeout to run after scrolling ends
  //     isScrolling = setTimeout(() => {
  //       // scrolling has ended
  //       console.log( 'Scrolling has stopped.' );
  //       isStarted = false;
  //       const scrollLeft = scrollContainerNode.scrollLeft;
  //       const delta = scrollLeftStart - scrollLeft;
  //       if (delta < -CONFIG.dragSnapThreshold) {
  //         snapToPage(scrollContainerNode.scrollLeft, -1);
  //       }
  //       else if (delta > CONFIG.dragSnapThreshold) {
  //         snapToPage(scrollContainerNode.scrollLeft, 1);
  //       }
  //     }, 70);

  //   }, false);
  // }, [scrollContainerNode, snapToPage]);

  // OTHER HOOKS
  const scrollContainerCb = useCallback((node) => {
    // console.log('Scrollable.scrollContainerCb');
    if (node !== null && !scrollContainerNode) {
      // console.log('Scrollable.scrollContainerCbSET');
      // save ref for handling updates
      setScrollContainerNode(node);
      updateDOMDerivedVars(node);
    }
  }, [ updateDOMDerivedVars, scrollContainerNode ]);

  const pageLeft = () => {
    if (pagePosition > 0) {
      const newPagePosition = pagePosition - 1;
      // console.log('Scrollable.pageLeft', newPagePosition);
      setPagePosition(newPagePosition);
      paginationRef.current.pagePositionUpdate(newPagePosition);
    }
  };
  const pageRight = () => {
    if (pagePosition < pageCount - 1) {
      const newPagePosition = pagePosition + 1;
      // console.log('Scrollable.pageRight', newPagePosition);
      setPagePosition(newPagePosition);
      paginationRef.current.pagePositionUpdate(newPagePosition);
    }
  };

  useImperativeHandle(ref, () => ({
    pageLeft,
    pageRight,
    updateDOMDerivedVars: () => {
      if (scrollContainerNode) {
        updateDOMDerivedVars(scrollContainerNode);
      }
    },
  }));

  // METHODS
  const snapToPage = (direction) => {
    // this allows over-scrolling (scrolling more than one page, when last page is really narrow for instance)
    let page = scrollContainerNode.scrollLeft / pageWidth;
    if (direction <= 0) {
      page = Math.floor(page);
    }
    if (direction > 0) {
      page = Math.ceil(page);
    }
    setPagePosition(page);

    // console.log(scrollLeft, direction, pageWidth, page);
  };

  // HELPERS, HANDLES, RENDERS, EVENTS
  const onClick = (event) => {
    if (scrollOnClick) {
      const { pageX } = event;
      const { left } = scrollContainerNode.getBoundingClientRect();
      const { clientWidth } = scrollContainerNode;

      // scroll by a 2 fifths clientWidth if the click occurred within the first or last quarter of the component

      const leftScroll = pageX <= left + clientWidth / 4;
      const rightScroll = pageX >= left + (clientWidth * 3) / 4;

      if (leftScroll || rightScroll) {
        scrollContainerNode.scrollBy({
          left: ((leftScroll ? -1 : 1) * clientWidth) / 2.5,
          behavior: 'smooth',
        });
      }
    }
  };

  const keyUp = (event) => {
    if (event.key === 'ArrowLeft') {
      pageLeft();
    } else if (event.key === 'ArrowRight') {
      pageRight();
    }
  };

  const dragStart = (event) => {
    const pageX = event.pageX || event.touches[0].pageX;
    setDragging(true);
    setDragStartX(pageX);
    setDragStartScroll(scrollContainerNode.scrollLeft);

    // console.log('start', event);
    // console.log('pageX', pageX);
  };

  const dragMove = (event) => {
    if (dragging) {
      const pageX = event.pageX || event.changedTouches[0].pageX;
      const delta = dragStartX - pageX;
      const scrollLeft = dragStartScroll + delta;
      scrollContainerNode.scroll({ left: scrollLeft });

      // console.log('dragging', delta, scrollLeft);
    }
  };

  const dragEnd = (event) => {
    // console.log('end', event);
    if (dragging) {
      setDragging(false);
      const pageX = event.pageX || event.changedTouches[0].pageX;
      // console.log('pageX');
      const delta = dragStartX - pageX;
      // console.log('delta', delta);
      if (delta < -CONFIG.dragSnapThreshold) {
        // console.log('pageLeft');
        onClickBlock();
        snapToPage(-1);
      } else if (delta > CONFIG.dragSnapThreshold) {
        // console.log('pageRight');
        onClickBlock();
        snapToPage(1);
      } else {
        // console.log('no page, reset');
        scrollContainerNode.scroll({ left: dragStartScroll, behavior: 'smooth' });
      }
    }
  };

  // RENDER: Scrollable
  let listeners = {};
  let containerListeners = {};
  if (pagination) {
    listeners = {
      ...listeners,
      onKeyUp: keyUp,
    };
  }
  if (drag) {
    containerListeners = {
      ...containerListeners,
      onMouseDown: dragStart,
      onMouseMove: dragMove,
      onMouseUp: dragEnd,
      onMouseLeave: dragEnd,
      onTouchStart: dragStart,
      onTouchMove: dragMove,
      onTouchEnd: dragEnd,
    };
  }
  if (scrollOnClick) {
    containerListeners = {
      ...containerListeners,
      onClick,
    };
  }
  return (
    <div
      className={classnames(styles.scrollable, {
        [styles.fade]: fade,
        [styles.fadeWhite]: fadeWhite,
        [styles.noMargins]: noMargins,
        [styles.chartMargins]: chartMargins,
        [styles.scroll]: scroll,
        [styles.noScrollbar]: true, // or !scrollbar to disable scrollbar prop
        [styles.showPaginationBubbles]: showPaginationBubbles,
        [styles.hidden]: startRight && (!startedRight || !startedRightDone),
      })}
      role='menuitem'
      tabIndex={0}
      {...listeners}
    >

      { /* SCROLL CONTAINER */ }
      <div
        ref={scrollContainerCb}
        className={classnames(styles.scrollContainer, { [styles.extraPaddings]: extraPaddingsForFadedScrollable })}
        {...containerListeners}
      >


        { /* CHILDREN */ }
        { !breakout && children }

        { /* CHILDREN + BREAKOUT */ }
        { !!breakout && (
          <div
            className={styles.breakingContainer}
            style={{ width: `calc(100% - ${2 * breakout}px)` }}
          >

            { /* CONTENT BUFFER */ }
            <div
              className={styles.breakoutBuffer}
              style={{ minWidth: `${breakout}px` }}
            />

            { children }

            { /* CONTENT BUFFER */ }
            <div
              className={styles.breakoutBuffer}
              style={{ minWidth: `${breakout}px` }}
            />
          </div>
        ) }

      </div>

      { /* CONTROLS */ }
      { pagination && (
        <div className={styles.controls}>
          <Pagination
            ref={paginationRef}
            showPaginationBubbles={showPaginationBubbles}
            showNumbers={showPaginationNumbers}
            showPagerButtons={showPagerButtons}
            extraMargins={chartMargins}
            pageCount={pageCount}
            pagePosition={pagePosition}
            onPagePositionUpdate={(newPagePosition, pageLeftAvailable, pageRightAvailable, pageToLeft) => {
              setPagePosition(newPagePosition);
              onPaginationAvailabilities([
                pageLeftAvailable,
                pageRightAvailable,
                newPagePosition,
                pageToLeft,
              ]);
            }}
          />
        </div>
      ) }
    </div>
  );
};

export default forwardRef(Scrollable);
